import Head from "next/head";

export default function PageHead({ title }: { title?: string }) {
  const fullTitle = title
    ? `${title} | ServiceUp for Shops`
    : "ServiceUp for Shops";
  return (
    <Head>
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <title>{fullTitle}</title>
    </Head>
  );
}
